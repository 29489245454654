import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import OpenOne from './OpenOne'
import CloseOne from './CloseOne'
import OpenTwo from './OpenTwo'
import CloseTwo from './CloseTwo'
import CloseOneSmall from './CloseOneSmall'
import OpenOneSmall from './OpenOneSmall'
import CloseTwoSmall from './CloseTwoSmall'
import OpenTwoSmall from './OpenTwoSmall'


function DeviceRegistrationn() {
    const [ip, setIP] = useState('')
    const [divOne, setDivOne] = useState(false)
    const [divTwo, setdivTwo] = useState(false)
    
    const getIP = async () => {
        const res = await axios.get('https://api.ipify.org/?format=json')
        setIP(res.data.ip)
    }

    useEffect(() => {
        //passing getIP method to the lifecycle method
        getIP()
    }, [])

    const openOne = () => {
        if (divOne === false) {
            setDivOne(true)
            setdivTwo(false)
            return
        }
        setDivOne(false)
    }

    const openTwo = () => {
        if (divTwo === false) {
            setdivTwo(true)
            setDivOne(false)
            return
        }
        setdivTwo(false)
    }

    return (
        <>
            <div className="  bg-darkGreen ">
                <div className=" relative mx-auto max-w-8xl px-8 py-10 sm:px-20  md:px-16 lg:px-36  ">
                    {/* big screen */}
                    <div className="  hidden sm:block ">
                        {/* Image */}
                        <div className="absolute right-20  top-0 hidden w-2/5  pl-20 md:right-16 md:block xl:right-10  ">
                            <img src={'images/image003.png'} alt="" />
                        </div>
                        {/* Left Text */}
                        <h3 className="body-font  font-outfit text-1.5xl font-normal	text-highlightGreen	">
                        Welcome to the network access portal
                        </h3>
                        <h1 className="my-8 pb-4 font-outfit text-5xl font-bold text-light 	leading-none	   sm:text-5xl md:w-3/5  ">
                            Student or staff access
                        </h1>
                        <h3 className="body-font text-md pb-4 font-outfit   font-normal text-light	  sm:text-xl md:w-1/2 	">
                            Please select an option below to connect your device
                            to the University of Exeter network.
                        </h3>

                        {/* Student or Staff */}

                        <div className="mt-8   md:w-1/2 ">
                            {/* paser */}

                            {/* paset */}
                            <div className="pb-0 text-left  ">
                                <hr className="   h-px rounded border-0 bg-deepGreen "></hr>
                                {divOne ? (
                                    <OpenOne clickFunction={() => openOne()} 
                                    internalIp={
                                        ip.slice(0, 8) === '144.173.'
                                    } />
                                ) : (
                                    <CloseOne clickFunction={() => openOne()} />
                                )}
                            </div>
                        </div>

                        {/* guest */}
                        <div className="mt-0   md:w-1/2 ">
                            <div className="pb-0 text-left  ">
                                {divTwo ? (
                                    <OpenTwo
                                        clickFunction={() => openTwo()}
                                        internalIp={
                                            ip.slice(0, 8) === '144.173.'
                                        }
                                    />
                                ) : (
                                    <CloseTwo clickFunction={() => openTwo()} />
                                )}
                            </div>
                            <hr className="   h-px rounded border-0 bg-deepGreen "></hr>
                        </div>
                    </div>

                    {/* small screen */}

                    <div className="  sm:hidden ">
                        {/* Left Text */}
                        <h3 className="body-font  font-outfit text-1.5xl font-normal	text-highlightGreen 		">
                        Welcome to the network access portal
                        </h3>
                        <h1 className="my-8 pb-4 font-outfit text-3xl font-black text-light    sm:text-5xl md:w-1/2  ">
                            Student or staff access
                        </h1>
                        <h3 className="body-font text-md pb-4 font-outfit   font-normal text-light	  sm:text-xl md:w-1/2 	">
                            Please select an option below to connect your device
                            to the University of Exeter network.
                        </h3>

                        <div className="mt-8   md:w-1/2 ">
                            <hr className="   h-px rounded border-0 bg-deepGreen "></hr>
                            {divOne ? (
                                <OpenOneSmall clickFunction={() => openOne()}
                                internalIp={
                                    ip.slice(0, 8) === '144.173.'
                                } />
                            ) : (
                                <button
                                    onClick={openOne}
                                    className="w-full pb-0 text-left "
                                >
                                    <CloseOneSmall />
                                </button>
                            )}
                        </div>

                        {/* guest */}

                        <div className="mt-0   md:w-1/2 ">
                            <hr className="   h-px rounded border-0 bg-deepGreen "></hr>

                            <div className=" w-full  ">
                                {divTwo ? (
                                    <OpenTwoSmall
                                        clickFunction={() => openTwo()}
                                        internalIp={
                                            ip.slice(0, 8) === '144.173.'
                                        }
                                    />
                                ) : (
                                    <button
                                        onClick={openTwo}
                                        className=" w-full"
                                    >
                                        <CloseTwoSmall />
                                    </button>
                                )}
                            </div>
                            <hr className="   h-px rounded border-0 bg-deepGreen "></hr>
                        </div>
                    </div>

                    <Link to="/">
                        <button
                            className="mt-10 w-full rounded-full border border-highlightGreen bg-darkGreen px-4 font-outfit
                    py-2 text-sm font-semibold text-highlightGreen hover:border-transparent hover:bg-deepGreen hover:text-white sm:w-auto"
                        >
                            Go back
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default DeviceRegistrationn
