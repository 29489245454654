import {  Link } from 'react-router-dom'

const GuestRegistration = () => {
    return (
        <>
            <div className="bg-darkGreen">
                <div className="relative mx-auto max-w-8xl  px-8 py-10 sm:px-20  md:px-16 lg:px-36  ">
                    <div className=" ">
                        {/* Image */}
                        <div className="absolute right-20  top-0 hidden w-2/5  pl-20 md:right-16 md:block lg:right-20  ">
                            <img src={'images/image002.png'} alt="" />
                        </div>
                        {/* Text */}
                        <h3 className="body-font  font-outfit text-1.5xl font-normal	text-highlightGreen 		">
                        Welcome to the network access portal
                        </h3>
                        <h1 className="my-8 font-outfit text-3xl font-bold text-light sm:text-5xl ">
                            Guest access
                        </h1>
                        <div className=" mt-8   md:w-1/2 ">
                            <p className="my-5 font-outfit text-xl  text-light	">
                                To connect to the University of Exeter Guest
                                wifi network, please complete the steps in one
                                of the two option below.
                            </p>
                        </div>
                        <h4 className="my-8 font-outfit text-xl font-bold text-light  ">
                            Guest wifi
                        </h4>

                        <ol className="my-5 list-decimal px-4 font-outfit text-xl		text-light">
                            <li>
                                Connect your device to the UOE-CONNECT wifi
                                network.
                            </li>
                            <li>
                                Open a web browser window. This may happen
                                automatically on some device.
                            </li>
                            <li>
                                You will be directed to the University of Exeter
                                wifi Portal page.
                            </li>
                            <li>Select “Login as a Guest”.</li>
                            <li>
                                Follow the on-screen instructions to get
                                connected.
                            </li>
                        </ol>

                        <h4 className="my-8 font-outfit text-xl font-bold text-light  ">
                            Guest wired connection
                        </h4>

                        <ol className="my-8 list-decimal px-4 font-outfit text-xl		text-light">
                            <li>
                                Connect your device to a wired network port.
                            </li>
                            <li>
                                Open a web browser window. This may happen
                                automatically on some device.
                            </li>
                            <li>
                                You will be directed to the University of Exeter
                                wifi Portal page.
                            </li>
                            <li>Select “Login as a Guest”.</li>
                            <li>
                                Follow the on-screen instructions to get
                                connected.
                            </li>
                        </ol>

                        <Link to="/">
                            <button
                                className="w-full rounded-full border border-highlightGreen bg-transparent px-4 font-outfit
                        py-2 text-sm font-semibold text-highlightGreen hover:border-transparent hover:bg-highlightGreen hover:text-white sm:w-auto"
                            >
                                Go back
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestRegistration
