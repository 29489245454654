function CloseTwo(props: { clickFunction: () => void }) {
    return (
        <>
            <button className=" text-left w-full " onClick={props.clickFunction}>
                <hr className="   h-px rounded border-0 bg-deepGreen "></hr>
                <div className=" relative flex  ">
                    <div
                        className=" m-auto h-24 py-4  pl-4 "
                    >
                        <svg
                            width="60"
                            height="61"
                            viewBox="0 0 60 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_240_796)">
                                <path
                                    d="M-0.0224609 26.0996C0.477539 24.4996 1.27754 23.2996 2.97754 22.7996C3.47754 22.5996 4.07754 22.5996 4.57754 22.5996C15.1775 22.5996 25.8775 22.5996 36.4775 22.5996C39.3775 22.5996 41.1775 24.3996 41.1775 27.2996C41.1775 33.7996 41.1775 40.1996 41.1775 46.6996C41.1775 49.4996 39.4775 51.2996 36.6775 51.3996C33.1775 51.4996 29.5775 51.3996 26.0775 51.3996C25.0775 51.3996 24.0775 51.3996 23.0775 51.4996C23.4775 51.5996 23.8775 51.5996 24.2775 51.6996C27.8775 52.4996 30.9775 54.1996 33.5775 56.8996C34.3775 57.6996 34.2775 58.7996 33.5775 59.4996C32.8775 60.2996 31.7775 60.2996 30.8775 59.4996C28.9775 57.5996 26.8775 56.1996 24.2775 55.5996C19.3775 54.2996 14.9775 55.4996 11.0775 58.7996C10.7775 58.9996 10.5775 59.2996 10.2775 59.4996C9.37754 60.2996 8.37754 60.2996 7.57754 59.4996C6.87754 58.7996 6.77754 57.5996 7.67754 56.8996C8.97754 55.6996 10.3775 54.5996 11.8775 53.6996C13.7775 52.4996 15.9775 51.7996 18.1775 51.3996C17.5775 51.3996 16.8775 51.3996 16.2775 51.3996C12.6775 51.3996 9.07754 51.3996 5.47754 51.3996C2.27754 51.3996 1.17754 50.6996 -0.0224609 47.7996C-0.0224609 40.4996 -0.0224609 33.2996 -0.0224609 26.0996ZM20.6775 26.2996C15.4775 26.2996 10.1775 26.2996 4.97754 26.2996C3.87754 26.2996 3.77754 26.3996 3.77754 27.4996C3.77754 33.7996 3.77754 40.0996 3.77754 46.4996C3.77754 47.4996 3.87754 47.5996 4.87754 47.5996C15.3775 47.5996 25.8775 47.5996 36.3775 47.5996C37.3775 47.5996 37.4775 47.3996 37.4775 46.4996C37.4775 40.1996 37.4775 33.7996 37.4775 27.4996C37.4775 26.4996 37.3775 26.3996 36.3775 26.3996C31.1775 26.2996 25.8775 26.2996 20.6775 26.2996Z"
                                    fill="#007D69"
                                />
                                <path
                                    d="M37.4773 0C38.6773 0.2 39.7773 0.3 40.9773 0.6C47.5773 2.2 52.7773 5.8 56.3773 11.6C58.5773 15.1 59.6773 18.9 59.9773 23C59.9773 23.4 60.0773 23.8 59.9773 24.3C59.9773 25.4 59.1773 26.3 58.1773 26.3C57.1773 26.3 56.2773 25.5 56.2773 24.3C56.1773 21.4 55.6773 18.6 54.3773 15.9C51.9773 10.7 48.1773 7.2 42.9773 5.1C40.7773 4.2 38.4773 3.8 36.0773 3.7C35.6773 3.7 35.1773 3.7 34.8773 3.5C34.1773 3.2 33.7773 2.7 33.7773 1.9C33.7773 1.1 34.0773 0.6 34.7773 0.2C34.8773 0.2 34.9773 0.1 35.0773 0C35.8773 0 36.6773 0 37.4773 0Z"
                                    fill="#007D69"
                                />
                                <path
                                    d="M50.3773 23.8996C50.4773 24.4996 50.3773 25.2996 49.5773 25.8996C48.3773 26.6996 46.8773 25.9996 46.6773 24.4996C46.5773 23.2996 46.4773 22.0996 46.1773 20.9996C44.8773 16.6996 41.0773 13.6996 36.5773 13.3996C36.2773 13.3996 35.9773 13.3996 35.6773 13.2996C34.5773 13.1996 33.7773 12.3996 33.7773 11.3996C33.8773 10.3996 34.6773 9.59961 35.7773 9.59961C39.3773 9.69961 42.4773 10.8996 45.2773 13.1996C48.3773 15.9996 50.0773 19.4996 50.3773 23.8996Z"
                                    fill="#007D69"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_240_796">
                                    <rect
                                        width="60"
                                        height="60.1"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="   grow px-8  py-8 ">
                        <div >
                            <h2 className=" font-outfit text-1.5xl font-bold  text-light   ">
                                Register an IoT device
                            </h2>
                        </div>
                    </div>
                    <div
                   
                        className=" absolute right-0   w-12 py-10 text-highlightGreen "
                    >
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.92886 7.09953L3.51465 8.51374L11.9999 16.999L20.4852 8.51374L19.071 7.09953L11.9999 14.1706L4.92886 7.09953Z"
                                fill="#00C896"
                            />
                        </svg>
                    </div>
                </div>
            </button>
        </>
    )
}

export default CloseTwo
