import QuestionCard from './question_one'

const Home = () => {
    return (
        <div className=" bg-darkGreen ">
            <QuestionCard />
        </div>
    )
}

export default Home
