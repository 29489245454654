import { Link } from 'react-router-dom'

function QuestionCard() {
    return (
        <>
            <div className=" relative mx-auto max-w-8xl px-8 py-10 sm:px-20  md:px-16 lg:px-36  ">
                <div className=" ">
                    {/* Image */}
                    <div className="absolute right-20  top-0 hidden w-2/5  pl-20 md:right-16 md:block lg:right-20  ">
                        <img src={'images/image001.png'} alt="" />
                    </div>
                    {/* Left Text */}
                    <h3 className="body-font hidden font-outfit text-1.5xl 	text-highlightGreen md:block		">
                        Welcome to the network access portal
                    </h3>
                    <h1 className="my-8 font-outfit text-3xl		 font-bold text-light sm:text-5xl ">
                        Get connected
                    </h1>
                    <h3 className="body-font text-xl font-outfit font-normal   text-light sm:text-xl		">
                        Please choose an option below to get started.
                    </h3>
                    {/* Student or Staff */}
                    <div className="mt-8   md:w-1/2 ">
                        <Link to="/DeviceRegistrationn">
                            <hr className=" mb-8  h-px rounded border-0 bg-deepGreen "></hr>
                            <div className="justify-betwee  sm:flex ">
                                <h2 className=" flex-1 font-outfit text-2xl  text-highlightGreen  ">
                                    I am a University of Exeter student or
                                    member of staff
                                </h2>
                                <div className=" ml-12 mr-4  hidden w-8 text-highlightGreen sm:block">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4 13L16.17 13L12.59 16.59L14 18L20 12L14 6L12.59 7.41L16.17 11L4 11L4 13Z"
                                            fill="#00C896"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4 className="my-5 grow font-outfit text-base  text-light">
                                Please select this option if you have a
                                University of Exeter IT account
                            </h4>
                            <div className="  mr-4   w-8 text-highlightGreen sm:hidden">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 13L16.17 13L12.59 16.59L14 18L20 12L14 6L12.59 7.41L16.17 11L4 11L4 13Z"
                                        fill="#00C896"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </div>

                    {/* I am a guest */}
                    <div className="mt-8   md:w-1/2 ">
                        <Link to="/GuestRegistration">
                            <hr className=" mb-8  h-px rounded border-0 bg-deepGreen "></hr>
                            <div className=" justify-betwee sm:flex ">
                                <h2 className="flex-1 font-outfit text-2xl  text-highlightGreen  ">
                                    I am a guest
                                </h2>
                                <div className=" ml-12 mr-4  hidden w-8 text-right text-highlightGreen sm:block">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4 13L16.17 13L12.59 16.59L14 18L20 12L14 6L12.59 7.41L16.17 11L4 11L4 13Z"
                                            fill="#00C896"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4 className="my-5 grow font-outfit text-base  text-light">
                                Please select this option if you are a visitor
                                to the University of Exeter
                            </h4>
                            <div className="  mr-4   w-8 text-highlightGreen sm:hidden">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M4 13L16.17 13L12.59 16.59L14 18L20 12L14 6L12.59 7.41L16.17 11L4 11L4 13Z"
                                        fill="#00C896"
                                    />
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionCard
