import { Outlet, Link } from 'react-router-dom'

const Layout = () => {
    return (
        <>
            <nav>
                <Link to="/">
                    <div className=" m-auto flex h-40 max-w-8xl items-center justify-center  px-16 sm:block sm:h-32 sm:px-24">
                        <img src={'images/logo.png'} alt="" />
                    </div>
                </Link>
            </nav>

            <Outlet />

            <div className=" m-auto  h-40 max-w-8xl    px-10 sm:block sm:px-28">
                <h4 className="mt-10 mb-5 font-outfit text-4xl font-normal  text-darkGreen sm:text-4xl ">
                    Need help?
                </h4>

                <ol className="mb-10 list-disc px-4 font-outfit text-xl	 text-darkGreen	">
                    <li>Read about our  <a className=' underline font-outfit text-xl text-darkGreen ' href='https://www.exeter.ac.uk/staff/policies/calendar/part1/otherregs/its/'>IT security</a> policies</li>
                    <li>Learn more from our FAQs before getting onboard</li>
                    <li>Please call the service desk on +44 (0) 1392 725050</li>
                </ol>
                <hr className="   h-0.5 rounded border-0 bg-gray-200 mt-5 mb-8 "></hr>

                <div className="hidden md:block md:pb-10">
                    <div className=" flex h-10 items-center justify-center  ">
                        <span className=" text-center font-outfit  text-xl  text-darkGreen ">
                            Copyright © University of Exeter 2023
                        </span>

                        {/* <span className="  text-center font-outfit text-xl  text-darkGreen  ">
                            &nbsp; | &nbsp;
                        </span> */}

                        {/* <a href="" className="text-xl text-highlightGreen">
                            Terms and Condition
                        </a> */}

                        {/* <span className=" text-center font-outfit  text-xl  text-darkGreen ">
                            &nbsp; | &nbsp;
                        </span> */}
                        
                        {/* <a href="" className="text-xl text-highlightGreen">
                            Privacy Policy
                        </a> */}
                    </div>
                </div>

                <div className="grid grid-cols-1 place-items-center gap-1 py-4 pb-10 md:hidden ">
                    <p className=" text-center font-outfit  text-xl  text-darkGreen ">
                        Copyright © University of Exeter 2023
                    </p>

                    <div>
                        {/* <a href="" className="text-xl text-highlightGreen">
                            Terms and Condition
                        </a> */}

                        {/* <span className=" text-center font-outfit  text-xl  text-darkGreen ">
                            &nbsp; | &nbsp;
                        </span> */}

                        {/* <a href="" className="text-xl text-highlightGreen">
                            Privacy Policy
                        </a> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout
