import { Link } from 'react-router-dom'

function OpenOne(props: { internalIp: boolean; clickFunction: () => void }) {
    return (
        <>
            <div className=" relative flex  bg-highlightGreen pl-4 ">
                <button
                    className="   mt-4 flex  "
                    onClick={props.clickFunction}
                    
                >
                    <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_240_655)">
                            <path
                                d="M0 57.5996C0.5 56.5996 1.3 56.1996 2.5 56.1996C10.1 56.1996 17.7 56.1996 25.3 56.1996C25.6 56.1996 25.9 56.1996 26.2 56.1996C26.2 53.6996 26.2 51.2996 26.2 48.6996C24.7 48.6996 23.2 48.6996 21.7 48.6996C17.5 48.6996 13.4 48.6996 9.2 48.6996C5.4 48.5996 2.9 44.8996 4.1 41.1996C5 38.5996 6 36.0996 7 33.5996C7.3 32.8996 7.7 32.3996 8.2 31.7996C8.5 31.4996 8.4 31.2996 8.3 30.8996C8 29.8996 7.6 28.8996 7.6 27.9996C7.5 23.0996 7.6 18.2996 7.6 13.3996C7.6 9.99961 10 7.59961 13.4 7.59961C19.5 7.59961 25.7 7.59961 31.8 7.59961C33 7.59961 33.9 8.39961 33.8 9.49961C33.8 10.5996 32.9 11.2996 31.7 11.2996C25.7 11.2996 19.7 11.2996 13.6 11.2996C11.9 11.2996 11.2 11.9996 11.2 13.6996C11.2 18.3996 11.2 22.9996 11.2 27.6996C11.2 29.3996 11.9 30.0996 13.6 30.0996C23.6 30.0996 33.6 30.0996 43.7 30.0996C46.9 30.0996 48.8 31.4996 49.7 34.4996C50.4 36.7996 51.2 38.9996 51.9 41.2996C53.2 45.3996 50.6 48.8996 46.3 48.8996C41.2 48.8996 36 48.8996 30.9 48.8996C30.6 48.8996 30.4 48.8996 30 48.8996C30 51.3996 30 53.8996 30 56.3996C30.3 56.3996 30.6 56.3996 30.9 56.3996C39.7 56.3996 48.5 56.3996 57.4 56.3996C58.6 56.3996 59.4 56.6996 60 57.7996C60 58.1996 60 58.4996 60 58.7996C59.5 59.2996 59.1 59.6996 58.7 60.1996C39.6 60.1996 20.5 60.1996 1.4 60.1996C0.9 59.6996 0.5 59.2996 0 58.7996C0 58.2996 0 57.9996 0 57.5996ZM28 44.9996C34.1 44.9996 40.2 44.9996 46.4 44.9996C48.1 44.9996 48.9 43.8996 48.4 42.2996C47.7 40.0996 47 37.7996 46.3 35.5996C45.9 34.1996 45.3 33.7996 43.9 33.7996C36.9 33.7996 29.9 33.7996 22.8 33.7996C19.4 33.7996 16 33.7996 12.6 33.7996C11.7 33.7996 10.9 34.0996 10.6 34.9996C9.6 37.4996 8.7 39.9996 7.8 42.5996C7.4 43.6996 8.1 44.6996 9.2 44.9996C9.3 44.9996 9.7 44.9996 10 44.9996C16 44.9996 22 44.9996 28 44.9996Z"
                                fill="#007D69"
                            />
                            <path
                                d="M60 21.3996C59.6 22.1996 59.4 23.0996 58.9 23.7996C57.8 25.3996 56.2 26.1996 54.2 26.1996C50.6 26.1996 46.9 26.1996 43.3 26.1996C39.9 26.1996 37.5 23.7996 37.5 20.3996C37.5 18.0996 37.5 15.6996 37.5 13.3996C37.5 10.8996 38.7 9.19961 40.8 7.99961C41 7.89961 41.2 7.69961 41.2 7.49961C41.2 3.79961 44.3 0.499609 47.6 0.199609L47.7 0.0996094C48.4 0.0996094 49.1 0.0996094 49.8 0.0996094L49.9 0.199609C53.1 0.499609 56.3 3.79961 56.2 7.49961C56.2 7.69961 56.3 7.89961 56.5 7.99961C58.4 8.89961 59.5 10.3996 60 12.3996C60 15.3996 60 18.3996 60 21.3996ZM48.7 11.2996C46.9 11.2996 45.1 11.2996 43.3 11.2996C41.9 11.2996 41.2 11.9996 41.2 13.3996C41.2 15.6996 41.2 18.0996 41.2 20.3996C41.2 21.7996 42 22.4996 43.3 22.4996C46.9 22.4996 50.4 22.4996 54 22.4996C55.4 22.4996 56.1 21.6996 56.1 20.3996C56.1 18.0996 56.1 15.7996 56.1 13.4996C56.1 11.9996 55.4 11.2996 53.8 11.2996C52.2 11.1996 50.5 11.1996 48.7 11.2996ZM45 7.49961C47.5 7.49961 50 7.49961 52.4 7.49961C52.4 5.39961 50.8 3.79961 48.7 3.79961C46.7 3.69961 45.1 5.29961 45 7.49961Z"
                                fill="#007D69"
                            />
                            <path
                                d="M28.1 41.2C26.9 41.2 25.8 41.2 24.6 41.2C23.4 41.2 22.5 40.4 22.5 39.3C22.5 38.2 23.3 37.5 24.5 37.5C26.9 37.5 29.3 37.5 31.7 37.5C32.9 37.5 33.7 38.3 33.7 39.3C33.7 40.4 32.8 41.2 31.6 41.2C30.5 41.3 29.3 41.2 28.1 41.2Z"
                                fill="#007D69"
                            />
                            <path
                                d="M48.8 19.6996C47.3 19.6996 46 18.4996 46 16.8996C46 15.3996 47.3 14.0996 48.8 14.0996C50.3 14.0996 51.6 15.2996 51.6 16.8996C51.6 18.3996 50.3 19.6996 48.8 19.6996Z"
                                fill="#007D69"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_240_655">
                                <rect width="60" height="60" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                <div className="  flex-1  px-8  py-8 ">
                    <button onClick={props.clickFunction}>
                        <h2 className="font-outfit text-1.5xl font-bold text-darkGreen   ">
                            Connect your device
                        </h2>
                    </button>
                    <p className="  py-4  font-outfit text-darkGreen text-sm ">
                        Please select this option to securely connect your
                        device (eg Laptop, Smartphone or Tablet) to eduroam or
                        the University wired network
                    </p>
                    {props.internalIp ?(          
                            <Link to="https://cloud.securew2.com/public/07468/eduroam_TLS_auto/">
                                <button
                                    className="rounded-full border border-darkGreen bg-darkGreen font-outfit
                            px-4 py-2 text-sm font-semibold text-light hover:border-transparent hover:bg-deepGreen hover:text-white"
                                >
                                    Go to secure portal
                                </button>
                            </Link>
                    ):(          
                            <Link to="https://cloud.securew2.com/public/07468/eduroam_TLS/">
                                <button
                                    className="rounded-full border border-darkGreen bg-darkGreen font-outfit
                            px-4 py-2 text-sm font-semibold text-light hover:border-transparent hover:bg-deepGreen hover:text-white"
                                >
                                    Go to secure portal
                                </button>
                            </Link>
                    )}
          
                </div>
                <button
                    onClick={props.clickFunction}
                    className="absolute right-0   ml-12 mr-4 w-8 py-8 text-highlightGreen"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.0711 16.9493L20.4854 15.5351L12.0001 7.04981L3.51479 15.5351L4.929 16.9493L12.0001 9.87823L19.0711 16.9493Z"
                            fill="#003C3C"
                        />
                    </svg>
                </button>
            </div>
        </>
    )
}

export default OpenOne
