import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import Home from './pages/Home'
import GuestRegistration from './pages/GuestRegistration'
import DeviceRegistrationn from './pages/DeviceRegistrationn'

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route
                        path="GuestRegistration"
                        element={<GuestRegistration />}
                    />
                    <Route
                        path="DeviceRegistrationn"
                        element={<DeviceRegistrationn />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App
